import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import backgrounds from "./wallpapers";


const randomBG = () => {
  const random = Math.floor(Math.random() * backgrounds.length) + 0;
  document.body.style.backgroundImage = `url(${backgrounds[random]})`
}
randomBG();
setInterval(randomBG, 7000);


ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
