import img1 from "./wall1.jpg";
import img2 from "./wall2.jpg";
import img8 from "./wall8.jpg";
import img10 from "./wall10.jpg";
import img12 from "./wall12.jpg";
import img13 from "./wall13.jpg";
import img14 from "./wall14.jpg";


export default [
  img1,
  img2,
  img8,
  img10,
  img12,
  img13,
  img14
];
